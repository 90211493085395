import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import BlockSection from 'src/components/blocksection';
import logoFooter from 'src/assets/images/logo.svg';
import logoFooterDark from 'src/assets/images/logo-dark.svg';
// import logoFooter from 'src/assets/images/logo-xmas.svg';
import { LabelLang } from 'src/lang/components';
import AppIcon from 'src/components/appIcon';
import BrowserDetect from 'src/services/browser-detect';
import { loadLazyImage } from 'src/utils/images';
import { URL } from 'src/resources/constants/url';

import CTA from './cta';
import Supports from './supports';
import About from './about';
import Information from './information';
import Content from './content';
import FooterMobile from '../footerMobile';
import ContactUs from './contactUs';
import MainPages from './mainPages';
import { FooterContainerStyled } from './styled';

const FooterStyled = styled.div`
  background-color: var(--background);
  color: var(--text-secondary);
`;

const FooterBlockSection = styled(BlockSection)`
  .container {
    max-width: 1280px;
  }
  > div:first-child {
    padding-top: 120px;
  }
`;

const AppIconWrapper = styled.div`
  .appIcon {
    margin-left: 0px;
  }
`;

function Footer({ theme }) {
  const isLockRedirect = useSelector(state => state.app.isLockRedirect);
  const location = useLocation();

  useEffect(() => {
    loadLazyImage();
  }, []);

  return (
    <>
      {location?.pathname !== URL.ABOUT_US && <CTA />}
      <FooterBlockSection theme={theme}>
        <FooterContainerStyled isLockRedirect={isLockRedirect}>
          <div className="branding">
            <div>
              <AppIconWrapper>
                <AppIcon className="logo">
                  <img className="lazy-image" data-src={theme === 'dark' ? logoFooterDark : logoFooter} alt="MyConstant alternative logo" />
                </AppIcon>
              </AppIconWrapper>
              {/* <HtmlLang id='footer.heading' /> */}
            </div>
            {/* <div className="reward">
              <div>
                <span className="semiBold name"><i className="far fa-copyright" /><LabelLang id="footer.company.name" /></span>
                <br />
                <LabelLang id="footer.company.address1" />, <LabelLang id="footer.company.address2" />
              </div>
            </div> */}
          </div>
          <MainPages />
          <About />
          {/* <OurProgram /> */}
          <div>
            <Supports />
            <ContactUs theme={theme} />
          </div>
        </FooterContainerStyled>
        <Information />
        <Content />
      </FooterBlockSection>
    </>
  );
}

const FooterAdapter = ({ theme }) => {
  const primary = theme === 'dark' ? 'white' : 'black';
  const secondary = theme === 'dark' ? '#A5ADCF' : '#59657F';
  const background = theme === 'dark' ? '#1e1f25' : 'white';
  const borderColor = theme === 'dark' ? '#34384c' : '#ececed';
  const style = {
    '--text-primary': primary,
    '--text-secondary': secondary,
    '--background': background,
    '--border': borderColor,
  };

  return (
    <FooterStyled theme={theme} style={style}>
      {BrowserDetect.isMobile
        ? <FooterMobile theme={theme} />
        : <Footer theme={theme} />
      }
    </FooterStyled>
  );
};

export default FooterAdapter;
