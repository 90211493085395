export const loadLazyImage = function() {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          if (entry.target.hasAttribute('data-src')) {
            entry.target.setAttribute(
              'src',
              entry.target.getAttribute('data-src'),
            );
            observer.unobserve(entry.target);
          }
        }
      });
    });
    document.querySelectorAll('.lazy-image').forEach(lazyImage => {
      if (
        lazyImage.getAttribute('is-observed') != 'true' &&
        lazyImage.getAttribute('data-src') != null
      ) {
        lazyImage.setAttribute('is-observed', 'true');
        observer.observe(lazyImage);
      }
    });
  } else {
    var imgList = document.querySelectorAll('.lazy-image');
    Array.prototype.forEach.call(imgList, function(image) {
      image.setAttribute('src', image.getAttribute('data-src'));
    });
  }
};

export const loadLazyBackground = function() {
  if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          if (entry.target.hasAttribute('data-src')) {
            entry.target.setAttribute(
              'style',
              `background-image: url(${entry.target.getAttribute('data-src')})`,
            );
            observer.unobserve(entry.target);
          }
        }
      });
    });
    document.querySelectorAll('.lazy-background').forEach(lazyImage => {
      if (
        lazyImage.getAttribute('is-observed') != 'true' &&
        lazyImage.getAttribute('data-src') != null
      ) {
        lazyImage.setAttribute('is-observed', 'true');
        observer.observe(lazyImage);
      }
    });
  } else {
    var imgList = document.querySelectorAll('.lazy-background');
    Array.prototype.forEach.call(imgList, function(image) {
      image.setAttribute('style', `background-image: url(${image.getAttribute('data-src')})`);
    });
  }
};