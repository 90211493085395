// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
import { API_URL, SOCIAL } from 'src/resources/constants/url';
import BrowserDetect from 'src/services/browser-detect';
import { makeRequest, SERVICES } from 'src/redux/action';
import { getSavedQueryString } from 'src/screens/referral/utils';
import Cookies from 'js-cookie';
import { COOKIE_QUERY_STRING } from 'src/resources/constants/app';
import LocalStore from 'src/services/localStore';

export const pageview = (/* pathname */) => {
  // window.gtag('config', process.env.social.googleAnalyticId, {
  //   // page_location: url,
  //   page_path: pathname
  // });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (/* { action, category, label, value } */) => {
  // window.gtag('event', action, {
  //   event_category: category,
  //   event_label: label,
  //   value,
  // });
};

export const getDownloadLink = (platform, action) => {
  let url = '';
  if(BrowserDetect.isMobile) {
    return SOCIAL.FIREBASE_LINK_DOWNLOAD.replace('{utm_source}', action).replace('{ct}', action);
  } else {
    if(platform === 'ios') {
      url = SOCIAL.APP_STORE;
    } else {
      url =SOCIAL.GOOGLE_PLAY;
    }
  }

  return url;
};

const getTrackIpInfo = (info) => {
  const queryString = getSavedQueryString();
  Cookies.remove(COOKIE_QUERY_STRING);
  LocalStore.remove(COOKIE_QUERY_STRING);
  return `${info}${BrowserDetect.isMobile ? '_mobile_web_action' : ''}|${queryString}`;
};

export const trackIpInfo = (category, info) => makeRequest({
  type: 'TRACK_IP_INFO',
  url: API_URL.SYSTEM.TRACK_IP_INFO,
  service: SERVICES.ROOT,
  withAuth: false,
  data: { Category: category, Info: getTrackIpInfo(info) },
  method: 'POST'
});