import Cookies from 'js-cookie';
import {
  COOKIE_NEWYEAR_SALE,
  COOKIE_QUERY_STRING,
  COOKIE_REFERRAL,
  COOKIE_SRC,
} from 'src/resources/constants/app';
import LocalStore from 'src/services/localStore';

export const getSavedReferralCode = () => {
  let referral = Cookies.get(COOKIE_REFERRAL) || '';
  if (referral ===  'undefined' || referral === 'false' || !referral) {
    referral = LocalStore.get(COOKIE_REFERRAL) || '';
    if (referral ===  'undefined' || referral === 'false' || !referral) {
      Cookies.remove(COOKIE_REFERRAL);
      return '';
    } else {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_REFERRAL, referral, { expires: now });
      return referral;
    }
  }
  return referral || '';
};

export const getSavedSrc = () => {
  let src = Cookies.get(COOKIE_SRC) || '';
  if (src ===  'undefined' || src === 'false' || !src) {
    src = LocalStore.get(COOKIE_SRC) || '';
    if (src ===  'undefined' || src === 'false' || !src) {
      Cookies.remove(COOKIE_SRC);
      return '';
    } else {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_SRC, src, { expires: now });
      return src;
    }
  }
  return src || '';
};

export const getSavedQueryString = () => {
  let queryString = Cookies.get(COOKIE_QUERY_STRING) || '';
  if (queryString ===  'undefined' || queryString === 'false' || !queryString) {
    queryString = LocalStore.get(COOKIE_QUERY_STRING) || '';
    if (queryString ===  'undefined' || queryString === 'false' || !queryString) {
      Cookies.remove(COOKIE_QUERY_STRING);
      return '';
    } else {
      const now = new Date();
      now.setTime(now.getTime() + (5 * 365 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_QUERY_STRING, queryString, { expires: now });
      return queryString;
    }
  }
  return queryString || '';
};

export const getSavedNewYearSales = () => {
  let newyearsales = Cookies.get(COOKIE_NEWYEAR_SALE) || '';
  if (newyearsales ===  'undefined' || newyearsales === 'false' || !newyearsales) {
    newyearsales = LocalStore.get(COOKIE_NEWYEAR_SALE) || '';
    if (newyearsales ===  'undefined' || newyearsales === 'false' || !newyearsales) {
      Cookies.remove(COOKIE_NEWYEAR_SALE);
      return '';
    } else {
      const now = new Date();
      now.setTime(now.getTime() + (30 * 24 * 60 * 60 * 1000));
      Cookies.set(COOKIE_NEWYEAR_SALE, newyearsales, { expires: now });
      return newyearsales;
    }
  }
  return newyearsales || '';
};
